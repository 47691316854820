import parser from './parser';

/* eslint-disable prettier/prettier */
export const StBVV_TabelleA = (inputValue, param) => {
  if (param !== undefined && inputValue < param) return StBVV_TabelleA(param)
  const inputParam = [300, 600, 900, 1200, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 6000, 7000, 8000, 9000, 10000, 13000, 16000, 19000, 22000, 25000, 30000, 35000, 40000, 45000, 50000, 65000, 80000, 95000, 110000, 125000, 140000, 155000, 170000, 185000, 200000, 230000, 260000, 290000, 320000, 350000, 380000, 410000, 440000, 470000, 500000, 550000, 600000, 5000000, 25000000];
  const outputValue = [29, 53, 76, 100, 123, 157, 189, 222, 255, 288, 321, 354, 398, 441, 485, 528, 571, 618, 665, 712, 759, 806, 892, 977, 1061, 1146, 1230, 1320, 1411, 1502, 1593, 1683, 1773, 1864, 1954, 2045, 2136, 2275, 2414, 2552, 2697, 2760, 2821, 2882, 2939, 2995, 3051, 3132, 3211, 3211 + Math.ceil((inputValue - 600000) / 50000) * 141, 15619 + Math.ceil((inputValue - 5000000) / 50000) * 106, 58019 + Math.ceil((inputValue - 25000000) / 50000) * 83];
  let i = 0;
  while (i < inputParam.length && inputParam[i] < inputValue) i += 1;
  return outputValue[i];
};

export const StBVV_TabelleB = (inputValue, param) => {
  if (param !== undefined && inputValue < param) return StBVV_TabelleB(param)
  const inputParam = [3000, 3500, 4000, 4500, 5000, 6000, 7000, 8000, 9000, 10000, 12500, 15000, 17500, 20000, 22500, 25000, 37500, 50000, 62500, 75000, 87500, 100000, 125000, 150000, 175000, 200000, 225000, 250000, 300000, 350000, 400000, 450000, 500000, 625000, 750000, 875000, 1000000, 1250000, 1500000, 1750000, 2000000, 2250000, 2500000, 3000000, 3500000, 4000000, 4500000, 5000000, 7500000, 10000000, 12500000, 15000000, 17500000, 20000000, 22500000, 25000000, 30000000, 35000000, 40000000, 45000000, 50000000, 125000000, 250000000]
  const outputValue = [46, 54, 64, 72, 81, 91, 99, 109, 114, 120, 126, 142, 157, 168, 180, 190, 203, 248, 286, 319, 333, 348, 399, 444, 483, 517, 549, 578, 605, 657, 704, 746, 785, 822, 913, 991, 1062, 1126, 1249, 1357, 1455, 1542, 1621, 1695, 1841, 1971, 2089, 2196, 2566, 2983, 3321, 3603, 3843, 4050, 4314, 4558, 5014, 5433, 5823, 6187, 6532, 6532 + Math.ceil((inputValue - 50000000) / 5000000) * 258, 6532 + 11250 + Math.ceil((inputValue - 125000000) / 12500000) * 450, 6532 + 11250 + 4500 + Math.ceil((inputValue - 250000000) / 25000000) * 642];
  let i = 0;
  while (i < inputParam.length && inputParam[i] < inputValue) i += 1;
  return outputValue[i];
};

export const StBVV_TabelleC = (inputValue, param) => {
  if (param !== undefined && inputValue < param) return StBVV_TabelleC(param)
  const inputParam = [15000, 17500, 20000, 22500, 25000, 30000, 35000, 40000, 45000, 50000, 62500, 75000, 87500, 100000, 125000, 150000, 200000, 250000, 300000, 350000, 400000, 450000, 500000];
  const outputValue = [68, 75, 83, 88, 95, 102, 110, 115, 122, 130, 137, 149, 164, 177, 197, 217, 259, 299, 339, 381, 416, 448, 483, 483 + Math.ceil((inputValue - 500000) / 50000) * 34];
  let i = 0;
  while (i < inputParam.length && inputParam[i] < inputValue) i += 1;
  return outputValue[i];
};

parser.functions.StBVV_TabelleA = StBVV_TabelleA;
parser.functions.StBVV_TabelleB = StBVV_TabelleB;
parser.functions.StBVV_TabelleC = StBVV_TabelleC;