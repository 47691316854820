import { isNumber } from 'lodash';
import { formatCurrency } from './calcUtils';

export const priceInfoAsText = ({ finalPrice, priceBeforeDiscount, paymentInterval, discount, hideInterval, showDiscounts = true, currentFeeTypeMonthly }) => {
  if (currentFeeTypeMonthly && (paymentInterval === 'monthly' || paymentInterval === 'yearly')) {
    return {
      code: 'fixedMonthlyFeeHint',
      variables: {
        paymentInterval,
      },
    };
  }
  if (!finalPrice)
    return {
      code: 'free_service',
      variables: {
        paymentInterval,
      },
    };

  const isDiscounted = finalPrice !== priceBeforeDiscount && isNumber(finalPrice) && isNumber(priceBeforeDiscount);
  const formattedDiscount = discount?.value && (discount?.type === 'percent' ? `${discount?.value}%` : `${discount?.value} €`);

  const variables = {
    priceBeforeDiscountRaw: priceBeforeDiscount,
    finalPriceRaw: finalPrice,

    priceBeforeDiscount: formatCurrency(priceBeforeDiscount),
    finalPrice: formatCurrency(finalPrice),
    isDiscounted,
    paymentInterval: hideInterval ? '' : paymentInterval,
    discount: formattedDiscount,
    hideInterval: !!hideInterval,
  };
  let code = 'not_discounted';
  if (isDiscounted && showDiscounts) {
    if (discount?.value) code = 'discounted_with_value';
    else code = 'discounted_no_value';
  }
  return { code, variables };
};
