import { Text } from 'slate';
import React, { useContext } from 'react';
import { H1, H2, H3, P, A, OL, UL } from './styledComponents';

export const isRichTextString = (str) => str?.startsWith?.('[{"type"');

export const PlaceholderContext = React.createContext();
export const PlaceholderContextProvider = PlaceholderContext.Provider;
export const usePlaceholderContext = () => useContext(PlaceholderContext);

export const MentionElement = (props) => {
  const context = usePlaceholderContext();
  const str = String(context?.[props?.name] || '');
  // eslint-disable-next-line no-use-before-define
  if (isRichTextString(str)) return renderRichText(str);
  return str;
};

// https://docs.slatejs.org/concepts/09-serializing
// https://github.com/ianstormtaylor/slate/issues/3246
// https://github.com/ianstormtaylor/slate/issues/3899
export const renderRichText = (node) => {
  if (isRichTextString(node)) node = JSON.parse(node); // eslint-disable-line no-param-reassign
  if (Array.isArray(node)) return renderRichText({ children: node });
  if (Text.isText(node)) {
    let component = <span>{node.text}</span>;
    if (node.italic) component = <i>{component}</i>;
    if (node.bold) component = <b>{component}</b>;
    if (node.underline) component = <ins>{component}</ins>;
    return component;
  }

  const children = node?.children?.map?.((n) => renderRichText(n));
  let component;
  switch (node?.type) {
    case 'div':
      return <div>{children}</div>;
    case 'paragraph':
      return <P>{children}</P>;
    case 'bulleted-list':
      return <UL>{children}</UL>;
    case 'list-item':
      return <li>{children}</li>;
    case 'numbered-list':
      return <OL>{children}</OL>;
    case 'heading-one':
      return <H1>{children}</H1>;
    case 'heading-two':
      return <H2>{children}</H2>;
    case 'heading-three':
      return <H3>{children}</H3>;
    case 'mention':
      return <MentionElement {...node} />;
    case 'link':
      return (
        <A href={node.url} target="_blank" rel="noopener noreferrer">
          {children}
        </A>
      );
    default:
      component = children;
  }
  return component;
};

export const isRichTextEmpty = (node) => {
  let isEmpty = true;
  try {
    try {
      if (isRichTextString(node)) node = JSON.parse(node); // eslint-disable-line no-param-reassign
    } catch (e) {} // eslint-disable-line no-empty
    const isText = node?.[0]?.children?.length > 1 || node?.[0]?.children?.[0]?.text?.trim?.();
    const isList = node?.[0]?.children?.length > 1 || node?.[0]?.children?.[0]?.children?.length > 1 || node?.[0]?.children?.[0]?.children?.[0]?.text?.trim?.();
    if (isText || isList) isEmpty = false;
  } catch (e) {
    console.log(e);
  }
  return isEmpty;
};
