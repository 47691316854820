/* eslint-disable no-shadow */
import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';

const avoidPageBreak = () => {
  // https://stackoverflow.com/questions/9238868/how-do-i-avoid-a-page-break-immediately-after-a-heading
  return `page-break-inside: avoid;
  ::after {
    content: '';
    display: block;
    height: 200px;
    margin-bottom: -200px;
  }`;
};

const getProp = (path) => (props) => get(props, `theme.${path}`);

export const A = styled.a`
  display: inline-block;
`;

export const OL = styled.ol`
  font-family: ${getProp('textBlock.fontFamily')};
  color: ${getProp('textBlock.color')};
  font-size: ${getProp('textBlock.fontSize')};
  line-height: ${getProp('textBlock.lineHeight')};
  margin-bottom: ${getProp('textBlock.marginBottom')};
  margin-top: ${getProp('textBlock.marginTop')};
`;

export const UL = styled.ul`
  font-family: ${getProp('textBlock.fontFamily')};
  color: ${getProp('textBlock.color')};
  font-size: ${getProp('textBlock.fontSize')};
  line-height: ${getProp('textBlock.lineHeight')};
  margin-bottom: ${getProp('textBlock.marginBottom')};
  margin-top: ${getProp('textBlock.marginTop')};
`;

export const Title = styled.h1`
  text-align: center;
  font-weight: normal;
  ${avoidPageBreak()}

  font-family: ${getProp('title.fontFamily')};
  color: ${getProp('title.color')};
  font-size: ${getProp('title.fontSize')};
  line-height: ${getProp('title.lineHeight')};
  margin-bottom: ${getProp('title.marginBottom')};
  margin-top: ${getProp('title.marginTop')};
`;

export const H1 = styled.h1`
  font-weight: bold;
  text-decoration: underline;
  ${avoidPageBreak()}
  font-family: ${getProp('h1.fontFamily')};
  color: ${getProp('h1.color')};
  font-size: ${getProp('h1.fontSize')};
  line-height: ${getProp('h1.lineHeight')};
  margin-bottom: ${getProp('h1.marginBottom')};
  margin-top: ${getProp('h1.marginTop')};
`;

export const H2 = styled.h2`
  font-weight: bold;
  ${avoidPageBreak()}
  font-family: ${getProp('h2.fontFamily')};
  color: ${getProp('h2.color')};
  font-size: ${getProp('h2.fontSize')};
  line-height: ${getProp('h2.lineHeight')};
  margin-bottom: ${getProp('h2.marginBottom')};
  margin-top: ${getProp('h2.marginTop')};
`;

export const H3 = styled.div`
  font-weight: normal;
  text-decoration: underline;
  ${avoidPageBreak()}
  font-family: ${getProp('h3.fontFamily')};
  color: ${getProp('h3.color')};
  font-size: ${getProp('h3.fontSize')};
  line-height: ${getProp('h3.lineHeight')};
  margin-bottom: ${getProp('h3.marginBottom')};
  margin-top: ${getProp('h3.marginTop')};
`;

// p is the same as the text block
export const P = styled.div`
  font-family: ${getProp('textBlock.fontFamily')};
  color: ${getProp('textBlock.color')};
  font-size: ${getProp('textBlock.fontSize')};
  line-height: ${getProp('textBlock.lineHeight')};
  margin-bottom: ${getProp('textBlock.marginBottom')};
  margin-top: ${getProp('textBlock.marginTop')};
`;

export const Testimonial = styled.div`
  font-style: italic;
  font-family: ${getProp('testimonial.fontFamily')};
  color: ${getProp('testimonial.color')};
  font-size: ${getProp('testimonial.fontSize')};
  line-height: ${getProp('testimonial.lineHeight')};
  margin-bottom: ${getProp('testimonial.marginBottom')};
  margin-top: ${getProp('testimonial.marginTop')};
`;

export const TestimonialSmallText = styled(Testimonial)`
  font-size: ${getProp('testimonialSmallText.fontSize')};
  line-height: ${getProp('testimonialSmallText.lineHeight')};
  margin-bottom: ${getProp('testimonialSmallText.marginBottom')};
  margin-top: ${getProp('testimonialSmallText.marginTop')};
`;

export const Italic = styled.i``;

export const Page = styled.main`
  font-family: ${getProp('main.fontFamily')};
`;

export const SubTitle = styled(P)`
  text-align: center;
`;

export const BodyPrimary = styled(P)`
  padding: 25px 5px 40px 5px;

  font-family: ${getProp('bodyPrimary.fontFamily')};
  background: ${getProp('bodyPrimary.background')};
  color: ${getProp('bodyPrimary.color')};
  margin-bottom: ${getProp('bodyPrimary.marginBottom')};
  margin-top: ${getProp('bodyPrimary.marginTop')};
  > * {
    color: ${getProp('bodyPrimary.color')};
    font-family: ${getProp('bodyPrimary.fontFamily')};
  }
`;

export const BodyPrimaryFooter = styled(P)`
  font-weight: bold;

  font-family: ${getProp('bodyPrimaryFooter.fontFamily')};
  color: ${getProp('bodyPrimaryFooter.color')};
`;

export const Signatures = styled.div`
  display: flex;
  margin-top: 100px;
  > div {
    width: 40%;
    margin-right: 10%;
    border-width: 2px;
    border-style: solid none none none;
  }
`;

export const Wrapper = styled.section`
  width: 100%;
`;

export const U = styled(P)`
  text-decoration: underline;
`;

// ----------Header/Footer----------
export const FooterContainer = styled.footer`
  width: 100%;
  font-size: 8pt;
  display: flex;
  padding-left: 1.2cm;
  padding-right: 1.2cm;
  font-family: ${getProp('main.fontFamily')};
`;

export const StyledHeaderComponent = styled.header`
  width: 100%;
  padding-left: 1.85cm;
  padding-right: 1.85cm;
  font-size: 0.5cm;
`;

export const CenterText = styled.section`
  margin-left: auto;
  margin-right: auto;
`;

export const Pagination = styled.section`
  font-size: 8em;
`;

export const HR = styled.div`
  width: 104%;
  margin-top: 35px;
  margin-left: -2%;
  height: 3px;
  background-color: ${getProp('main.primaryColor')};
`;

// ----------table----------

export const Table = styled.table`
  border-spacing: 2px;
  width: calc(100% + 4px);
  margin-left: -2px;
  background: white;
  font-size: ${getProp('table.fontSize')};
  margin-top: ${getProp('table.marginTop')};
  margin-bottom: ${getProp('table.marginBottom')};
  table {
    width: 100%;
    margin-left: 0px;
  }
`;

export const TableSecondary = styled(Table)`
  td:first-child {
    text-align: left !important;
  }
  td:last-child {
    text-align: right;
  }
  thead {
    background: ${getProp('tableHeaderSecondary.background')};
    color: ${getProp('tableHeaderSecondary.color')};
    > tr > td:first-child {
      width: 70%;
      ${'' /* max-width: 483px; */}
    }
  }
  table {
    tr > td:first-child {
      width: 70.5%;
      ${'' /* max-width: 475px; */}
    }
  }
`;

export const TablePrimary = styled(TableSecondary)`
  thead {
    background: ${getProp('tableHeaderPrimary.background')};
    color: ${getProp('tableHeaderPrimary.color')};
  }
`;

export const Thead = styled.thead`
  font-weight: bold;
`;

export const Tbody = styled.tbody`
  background: ${getProp('tableContent.background')};
  color: ${getProp('tableContent.color')};
`;

export const Tfoot = styled.tfoot`
  font-weight: bold;
  background: ${getProp('tableFooter.background')};
  color: ${getProp('tableFooter.color')};
`;

export const Tr = styled.tr``;

export const Td = styled.td`
  padding: 10px 6px;
  word-wrap: break-word;
`;

export const TdLeft = styled(Td)`
  width: 200px;
  max-width: 200px;
  font-weight: bold;
  background: ${getProp('tableLeftColumn.background')};
  color: ${getProp('tableLeftColumn.color')};
`;

// ----------Table Of Content----------

const Span = styled.span`
  display: inline-block;
`;

const TOCRowLink = styled.a`
  display: inline-block;
  font-family: ${getProp('tableOfContent.fontFamily')};
  color: ${getProp('tableOfContent.color')};
  font-size: ${getProp('tableOfContent.fontSize')};
  text-decoration: none;
`;

const Row = styled.div`
  display: flex;
  font-family: ${getProp('tableOfContent.fontFamily')};
  color: ${getProp('tableOfContent.color')};
  font-size: ${getProp('tableOfContent.fontSize')};
  justify-content: space-between;
`;

export const ContentRow = ({ left, right, level = 0, link, maxWidth }) => {
  const fontWeight = level === 0 ? 'bold' : 'normal';
  const marginTop = level ? 5 : 13;
  return (
    <Row style={{ fontWeight, marginTop, maxWidth }}>
      <TOCRowLink style={{ marginLeft: 40 * level }} href={link}>
        {left}
      </TOCRowLink>
      <Span className="display-none-client" style={{ textAlign: 'right' }}>
        {right}
      </Span>
    </Row>
  );
};
