import { filter, find, map, merge, sum } from 'lodash';
import { applyDiscountToValue } from '.';
import { inputFieldPaymentInterval } from '../constants';
import { calcWithRawFormula, getItemFormulaWithScales } from '../mentions/utils';
import { variablesFromCategory } from './calcUtils';

const sumByWithError = (e, fn) => {
  const values = map(e, fn);
  return values.includes('error') ? 'error' : sum(values);
};
const calcMonthlyAdvanceOfCategoryTotal = ({ monthlyDiscounted, yearlyDiscounted }) => {
  return (monthlyDiscounted || 0) + (yearlyDiscounted || 0) / 12;
};
export const calcItem = ({ items, item, variables, depth = 0, calculated = {}, calcCategoryTotalById, allCategoryIds = [] }) => {
  let value = 0;
  if (item.pricingFormula) {
    if (depth === 5) throw new Error('circular dep');
    const itemsUsedInFormula = items.filter((otherItem) => otherItem._id !== item._id && item.pricingFormula.includes(otherItem._id));
    const categoryIdsUsedInFormula = allCategoryIds.filter((categoryId) => categoryId !== item.categoryId && item.pricingFormula.includes(categoryId));
    const categoryTotals = merge(...categoryIdsUsedInFormula.map((id) => ({ [id]: calcMonthlyAdvanceOfCategoryTotal(calcCategoryTotalById(id)) })));
    // console.log(itemsUsedInFormula);
    // if (find(itemsUsedInFormula, (i) => itemIdsUsed.includes(i._id))) throw new Error('circular dep');
    const calculatedItems = itemsUsedInFormula.map((itemUsed) => [itemUsed._id, calcItem({ items, variables, item: itemUsed, depth: depth + 1, calculated })]);
    let currentPricingFormula = getItemFormulaWithScales({ pricingFormula: item.pricingFormula, scales: item.scales });
    if (calculatedItems.length)
      calculatedItems.forEach(([itemId, calculatedItemValue]) => {
        currentPricingFormula = currentPricingFormula.replace(new RegExp(`@{{${itemId}}}`, 'ig'), calculatedItemValue);
      });

    value = calcWithRawFormula({ variables: { ...variables, ...categoryTotals }, expression: currentPricingFormula, minPrice: item.minPrice });
  }
  // eslint-disable-next-line no-param-reassign
  calculated[item._id] = value;
  return value;
};

export const calcCategoryTotal = ({ cartCategory, categories, inputFields, items, discount, throwErrors, variables = variablesFromCategory({ categories, inputFields, cartCategory }), calcCategoryTotalById, allCategoryIds }) => {
  const itemValues = map(cartCategory.itemIds, (_id) => {
    const item = find(items, { _id });
    if (!item) return null;
    let value = 0;
    try {
      value = calcItem({ items, item, variables, calcCategoryTotalById, allCategoryIds });
    } catch (e) {
      if (!e.message.includes('is empty')) throw e;
      if (throwErrors) value = 'error';
    }
    return { value, paymentInterval: item.paymentInterval };
  });
  return Object.values(inputFieldPaymentInterval).reduce((prev, paymentInterval) => {
    const currentIntervalValues = filter(itemValues, { paymentInterval });
    const valuesSum = sumByWithError(currentIntervalValues, (v) => v.value || 0);
    const valuesDiscountedSum = applyDiscountToValue({ value: valuesSum, discount });
    return { ...prev, [paymentInterval]: valuesSum, [`${paymentInterval}Discounted`]: valuesDiscountedSum };
  }, {});
};
const calcCategoryTotalWithDiscount = ({ cartCategory, discounts, categories, inputFields, items, throwErrors, calcCategoryTotalById, allCategoryIds }) => {
  const discount = find(discounts, { _id: cartCategory.discountId });
  return calcCategoryTotal({ cartCategory, categories, inputFields, items, discount, throwErrors, calcCategoryTotalById, allCategoryIds });
};
export const calcCartTotal = ({ cart, categories, inputFields, items, discounts, throwErrors }) => {
  const allCategoryIds = categories.map((c) => c._id);
  const calcCategoryTotalById = (_id) => {
    const cartCategory = find(cart, { _id });
    return calcCategoryTotalWithDiscount({ cartCategory, discounts, categories, inputFields, items, throwErrors, calcCategoryTotalById, allCategoryIds });
  };
  const categoriesTotal = map(cart, (cartCategory) => calcCategoryTotalById(cartCategory._id));
  const total = Object.values(inputFieldPaymentInterval).reduce((prev, paymentInterval) => {
    const valuesDiscountedSum = sumByWithError(categoriesTotal, (v) => v[`${paymentInterval}Discounted`] || 0);
    const valuesSum = sumByWithError(categoriesTotal, (v) => v[paymentInterval] || 0);
    return { ...prev, [paymentInterval]: valuesSum, [`${paymentInterval}Discounted`]: valuesDiscountedSum };
  }, {});
  return total;
};
